import React from 'react';
import { SVG } from './primitives';

export const Skeletor = props => (
  <SVG viewBox="0 0 420 420" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fill-rule="evenodd">
      <path
        d="M209.999997 20c60.00001-10 83.487107 22.807098 121.24356 71.5 37.756452 48.692902 79.756427 208.167877 58.75644 248.5-20.999988 40.332123-80.000006 50-180 60C110 410 49 379.248383 29.999997 340 10.999993 300.751617 49 140.698357 88.756444 91.5 128.512888 42.301643 150 30 209.999997 20z"
        stroke="#999999"
        stroke-width="20"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="#999999"
      />
      <path
        d="M209 40.231213S229 40 269 69.734393c40 29.734395 73.794248 138.934242 59.999996 210.26561C315.205743 351.331373 248.999996 359.999988 209 360c-39.999997-.000012-103.76111 3.972824-120-79.999997-16.23889-83.97282 20-180.531215 60-210.26561C189 40 209 40.231213 209 40.231213z"
        stroke="#555555"
        stroke-width="30"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="#555555"
      />
      <g transform="translate(110 40)">
        <circle fill="#EEEEEE" cx="100" cy="100" r="100" />
        <g transform="translate(20 160)" fill="#EEEEEE">
          <path d="M20 60c11.045695 0 20-8.954305 20-20S11.045695.048416 0 0c20 20.01079 0 28.954305 0 40 0 11.045695 8.954305 20 20 20z" />
          <circle cx="50" cy="40" r="20" />
          <circle cx="80" cy="40" r="20" />
          <circle cx="110" cy="40" r="20" />
          <path d="M140 60c11.045695 0 20-8.954305 20-20s-16-20 0-40c-11.045695.038795-40 28.954305-40 40 0 11.045695 8.954305 20 20 20z" />
        </g>
        <g transform="translate(40 100)" fill="#555555">
          <circle cx="20" cy="20" r="20" />
          <circle cx="100" cy="20" r="20" />
        </g>
        <g fill="#555555">
          <path d="M92.5 180c5.522848 0 10-4.477153 10-10 0-5.522848-2.5-30-2.5-30s-17.5 24.477152-17.5 30c0 5.522847 4.477152 10 10 10z" />
          <path d="M108.5 180c5.522847 0 10-4.477153 10-10 0-5.522848-18.5-30-18.5-30s-1.5 24.477152-1.5 30c0 5.522847 4.477152 10 10 10z" />
        </g>
        <g fill="#EEEEEE">
          <path d="M20 259.31199S60 261.045694 60 250s-8.954305-20-20-20-20 8.954305-20 20v9.31199z" />
          <path d="M70 260s20 1.045695 20-10-8.954305-20-20-20-20 8.954305-20 20 20 10 20 10z" />
          <path d="M100 260s20 1.045695 20-10-8.954305-20-20-20-20 8.954305-20 20 20 10 20 10z" />
          <path d="M130 260s20 1.045695 20-10-8.954305-20-20-20-20 8.954305-20 20 20 10 20 10z" />
          <path d="M180 260v-10c0-11.045695-8.954305-20-20-20s-20 8.954305-20 20 40 10 40 10z" />
          <path d="M20 250h160v10H20z" />
        </g>
      </g>
    </g>
  </SVG>
);
