import { Scroller } from '@components/scroller';
import torsionsByWalterLeblanc from "../../../../content/assets/torsions/torsions-by-walter-leblanc-2.jpg";
import edgeCurveAnimated from "../../../../content/assets/torsions/edge-curve-animated.gif";
import edgeCurve from "../../../../content/assets/torsions/edge-curve.png";
import edgeCurveBottomAnimated from "../../../../content/assets/torsions/edge-curve-bottom-animated.gif";
import edgeCurveCpAnimated from "../../../../content/assets/torsions/edge-curve-cp-animated.gif";
import edgeCurveAnimatedCombined from "../../../../content/assets/torsions/edge-curve-animated-combined.gif";
import doubleCurve from "../../../../content/assets/torsions/double-curve.gif";
import edgeThickness from "../../../../content/assets/torsions/edge-thickness.gif";
import faceAndEdge from "../../../../content/assets/torsions/face-and-edge.gif";
import edgeThicknessPerspective from "../../../../content/assets/torsions/edge-thickness-perspective.gif";
import intersections from "../../../../content/assets/torsions/intersections.gif";
import edgeCurveSplit from "../../../../content/assets/torsions/edge-curve-split.gif";
import final from "../../../../content/assets/torsions/final.gif";
import * as React from 'react';
export default {
  Scroller,
  torsionsByWalterLeblanc,
  edgeCurveAnimated,
  edgeCurve,
  edgeCurveBottomAnimated,
  edgeCurveCpAnimated,
  edgeCurveAnimatedCombined,
  doubleCurve,
  edgeThickness,
  faceAndEdge,
  edgeThicknessPerspective,
  intersections,
  edgeCurveSplit,
  final,
  React
};