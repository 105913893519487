import { NoiseVsRandom, NoiseWave, Noise1D, Noise2D, Noise3D, Noise4D, Noise4DFancy } from "../../../../content/posts/noise/lazy-scenes";
import chromaticNoiseMp4 from "../../../../content/assets/chromatic-noise.mp4";
import animatedContoursMp4 from "../../../../content/assets/animated-contours.mp4";
import plasmaMp4 from "../../../../content/assets/plasma.mp4";
import vectorFieldMp4 from "../../../../content/assets/vector-field.mp4";
import flowFieldPlotterMp4 from "../../../../content/assets/flow-field-plotter.mp4";
import particleMovementMp4 from "../../../../content/assets/particle-movement.mp4";
import particlePathMp4 from "../../../../content/assets/particle-path.mp4";
import flowFieldMp4 from "../../../../content/assets/flow-field.mp4";
import marsMp4 from "../../../../content/assets/mars.mp4";
import mars3DMp4 from "../../../../content/assets/mars-3d.mp4";
import ghostPlanetsMp4 from "../../../../content/assets/ghost-planets.mp4";
import * as React from 'react';
export default {
  NoiseVsRandom,
  NoiseWave,
  Noise1D,
  Noise2D,
  Noise3D,
  Noise4D,
  Noise4DFancy,
  chromaticNoiseMp4,
  animatedContoursMp4,
  plasmaMp4,
  vectorFieldMp4,
  flowFieldPlotterMp4,
  particleMovementMp4,
  particlePathMp4,
  flowFieldMp4,
  marsMp4,
  mars3DMp4,
  ghostPlanetsMp4,
  React
};