import { Scroller } from '@components/scroller';
import { ConfettiVis } from "../../../../content/posts/confetti/confetti-vis";
import confettiParticle from "../../../../content/assets/confetti-particle.svg";
import confettiSpread from "../../../../content/assets/confetti-spread.svg";
import flocking from "../../../../content/assets/flocking.gif";
import confettiHeader from "../../../../content/assets/confetti-header.gif";
import * as React from 'react';
export default {
  Scroller,
  ConfettiVis,
  confettiParticle,
  confettiSpread,
  flocking,
  confettiHeader,
  React
};