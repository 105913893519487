import { SpaceDust, Sparks, SparkStorm, SolarStorm, SparksBasicDemo, SparkStormBasicDemo } from "../../../../content/posts/three-js-particles/lazy-scenes";
import lorenzAttractorGif from "../../../../content/assets/lorenz-attractor.gif";
import * as React from 'react';
export default {
  SpaceDust,
  Sparks,
  SparkStorm,
  SolarStorm,
  SparksBasicDemo,
  SparkStormBasicDemo,
  lorenzAttractorGif,
  React
};