import { Scroller } from '@components/scroller';
import { Hello3D, TetrisBlock, NoiseMaterial, BasicAnimation } from "../../../../content/posts/modular-webgl/lazy-scenes";
import cddMp4 from "../../../../content/assets/cdd.mp4";
import solarstormMp4 from "../../../../content/assets/solarstorm.mp4";
import cddStorybookMp4 from "../../../../content/assets/cdd-storybook.mp4";
import * as React from 'react';
export default {
  Scroller,
  Hello3D,
  TetrisBlock,
  NoiseMaterial,
  BasicAnimation,
  cddMp4,
  solarstormMp4,
  cddStorybookMp4,
  React
};