// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-post-layout-js": () => import("./../../../src/layouts/post-layout.js" /* webpackChunkName: "component---src-layouts-post-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-consolefont-js": () => import("./../../../src/pages/projects/consolefont.js" /* webpackChunkName: "component---src-pages-projects-consolefont-js" */),
  "component---src-pages-projects-homesick-js": () => import("./../../../src/pages/projects/homesick.js" /* webpackChunkName: "component---src-pages-projects-homesick-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-tethered-motion-js": () => import("./../../../src/pages/projects/tethered-motion.js" /* webpackChunkName: "component---src-pages-projects-tethered-motion-js" */),
  "component---src-pages-sketches-js": () => import("./../../../src/pages/sketches.js" /* webpackChunkName: "component---src-pages-sketches-js" */),
  "component---src-pages-speaking-js": () => import("./../../../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

