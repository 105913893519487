import { MVP, Shadows, DepthOfField } from "../../../../content/posts/storybook-day/lazy-scenes";
import spherePacking from "../../../../content/assets/sphere-packing.mp4";
import zOffset from "../../../../content/assets/z-offset.mp4";
import focusDistance from "../../../../content/assets/focus-distance.mp4";
import extrude from "../../../../content/assets/extrude.mp4";
import storybook7Mp4 from "../../../../content/assets/storybook-7.mp4";
import * as React from 'react';
export default {
  MVP,
  Shadows,
  DepthOfField,
  spherePacking,
  zOffset,
  focusDistance,
  extrude,
  storybook7Mp4,
  React
};